import React from 'react';
import "./DesignProject.css"; 

function ProjectMonsec() {
  return (

    <div className='design-project'>
      
      <div className='header'>
        <h1>project</h1>
        <h2>Monash<br/>Cybersecuity Club (MonSec)<br/> Capture The Flag</h2>
        <p>As a member of MonSec, I've contributed to our annual Capture The Flag (CTF) 
          event for university students. My role involved creating promotional material, 
          including posters and social media content, for this flagship competition. 
          MonSec is dedicated to cybersecurity exploration, and the CTF event showcases 
          computer security challenges, providing hands-on experience to foster a passion 
          for cybersecurity in students.</p>
        <div className='set'>
          <div className='skill'>Multimedia Publications</div>
        </div>
        <div className='set'>
          <div className='stack'>Illustrator</div>
        </div>
      </div>
      <div className='medium-space'></div>

      <img className='showcase-50h' src={process.env.PUBLIC_URL + "/projects/monsec/2.png"} alt="monsec stuff" />
      <div className='medium-space'></div>
      
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/monsec/1.png"} alt="monsec stuff" />
      <div className='medium-space'></div>

      <div class="showcase-2">
        <img  src={process.env.PUBLIC_URL + "/projects/monsec/3.png"} alt="monsec stuff" />
        <img  src={process.env.PUBLIC_URL + "/projects/monsec/4.png"} alt="monsec stuff" />
      </div>
      <div class="showcase-2">
        <img  src={process.env.PUBLIC_URL + "/projects/monsec/5.png"} alt="monsec stuff" />
        <img  src={process.env.PUBLIC_URL + "/projects/monsec/6.png"} alt="monsec stuff" />
      </div>
      <div className='medium-space'></div>
      
    </div>

  );
}

export default ProjectMonsec;