import React from 'react';

function ProjectDsow() {
  return (
    
    <div className='design-project'>
      
        <div className='header'>
          <h1>project</h1>
          <h2>Debate Society<br/>of Wellspring<br/>Logo Design</h2>
          <p>Since its inception in 2017, I have had the privilege of designing 
            and creating the logo for the Debate Society of Wellspring. 
            This logo has not only become an emblem of our club's identity but 
            has also played an integral role in our journey, symbolising our passion 
            for debate, critical thinking, and the art of constructive dialogue. 
          </p>
          <div className='set'>
            <div className='skill'>Logo Design</div>
          </div>
          <div className='set'>
            <div className='stack'>Photoshop</div>
            <div className='stack'>Illustrator</div>
            <div className='stack'>Indesign</div>
          </div>
        </div>
        <div className='medium-space'></div>

        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/dsow/1.png"} alt="dsow stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/dsow/2.png"} alt="dsow stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/dsow/3.png"} alt="dsow stuff" />
        <div className='medium-space'></div>
        
      </div>

  );
}

export default ProjectDsow;