import React from 'react';
import "./DesignProject.css";

function ProjectProm() {
  return (
    <div className='design-project'>
      <div className='header'>
        <h1>project</h1>
        <h2>Wellspring<br/>Avant-Garde Prom<br/>Tchaiskovsky'20</h2>
        <p>Embracing the enchanting theme of 'Tchaikovsky's 20,' inspired by Pyotr Ilyich Tchaikovsky's 
          iconic ballet 'Swan Lake,' our multimedia publications for the Wellspring Avant-Garde Prom invite 
          you into a world of grace and artistry. Like a swan's graceful reflection on a serene lake, our designs 
          mirror the timeless beauty of this annual event. From elegant invitations to captivating banners, our 
          creations encapsulate the essence of this extraordinary soirée, leaving a lasting impression on every guest.</p>
        <div className='set'>
          <div className='skill'>Multimedia Publications</div>
          <div className='skill'>Logo Design</div>
        </div>
        <div className='set'>
          <div className='stack'>Illustrator</div>
          <div className='stack'>Photoshop</div>
          <div className='stack'>Indesign</div>
        </div>
      </div>

      <div className='medium-space'></div>
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/7.png"} alt="prom stuff" />
      
    </div>
  );
}

export default ProjectProm;