import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import ProjectAsnz from './DesignProject/ProjectAsnz';
import ProjectBp from './DesignProject/ProjectBp';
import ProjectDsow from './DesignProject/ProjectDsow';
import ProjectMonsec from './DesignProject/ProjectMonsec';
import ProjectProm from './DesignProject/ProjectProm';
import ProjectWsc from './DesignProject/ProjectWsc';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" exact component={Home} /> {/* Home page */}
        <Route path="/project-monsec" component={ProjectMonsec} />
        <Route path="/project-asnz" component={ProjectAsnz} />
        <Route path="/project-prom" component={ProjectProm} />
        <Route path="/project-wsc" component={ProjectWsc} />
        <Route path="/project-dsow" component={ProjectDsow} />
        <Route path="/project-bp" component={ProjectBp} />
      </Switch>
    </Router>
  );
}

export default App;
