import React from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";

function Introduction({web_name}) {
  return (
    <div className='intro-section'>
      <div className="introduction">
        <div className="intro-left">
          <p>{web_name}</p>
        </div>
        <div className="intro-right">
          <p>
            Hi there! 
          </p>
          <p>
            I’m Chloe, a recent IT graduate from Monash University, 
            double-majored in Software Development & Business Information Systems 🎓
          </p>
          <p>
            With a background in graphic design, I’m keen to work in front-end development, 
            and eventually move into full-stack as my skillset levels up 👩🏻‍💻
          </p>
          <p>
          Check out some of my works below 🎨 
          </p>
        </div>
      </div>
      <SocialLinks/>
    </div>
  )
}

function SocialLinks() {
  return (
    <div className="social-links">
      <a
        href="mailto:linhchi311203@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <img
          src="/socials/email.png" // Update the path to your email icon
          alt="email icon"
          className="social-icon"
        />
      </a>

      <a
        href="https://www.linkedin.com/in/chloe-linh-chi-nguyen"
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <img
          src="/socials/linkedin.png" // Update the path to your LinkedIn icon
          alt="linkedin icon"
          className="social-icon"
        />
      </a>

      <a
        href="https://github.com/nglinhchi"
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <img
          src="/socials/github.png" // Update the path to your GitHub icon
          alt="github icon"
          className="social-icon"
        />
      </a>
    </div>
  );
}

function Tile({ line1, line2, line3, cover, path, isExternal }) {
  const tile_style = `tile-top ${cover}`;

  // Conditionally render a Link or an anchor tag based on the isExternal prop
  if (isExternal) {
    return (
      <a href={path} target="_blank" rel="noopener noreferrer" className="tile-link">
        <div className="tile">
          <div className={tile_style}></div>
          <div className="tile-bottom">
            <p className="tile-heading">
              {line1} <br />
              {line2} <br />
              ({line3})
            </p>
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <Link to={path} target="_blank" rel="noopener noreferrer" className="tile-link">
        <div className="tile">
          <div className={tile_style}></div>
          <div className="tile-bottom">
            <p className="tile-heading">
              {line1} <br />
              {line2} <br />
              ({line3})
            </p>
          </div>
        </div>
      </Link>
    );
  }
}


function DesignGroup() {
  return (
    <div className="group">
      <div className="group-name">
        [ GRAPHIC DESIGN ]
      </div>
      <div className="column">
        <div className="row">
          <Tile line1="Monash Cybersecurity Club CTF" line2="Multimedia Publications" line3="2023" cover="tile-monsec" path="/project-monsec"/>
          <Tile line1="Aviation School of New Zealand" line2="Brand Identity Guidelines" line3="2020" cover="tile-asnz"path="/project-asnz"/>
          <Tile line1="Wellspring Avant-Garde Prom" line2="Multimedia Publications" line3="2019" cover="tile-wagp" path="/project-prom"/>
        </div>
        <div className="row">
          <Tile line1="Wellspring Student Council" line2="Compiled Work" line3="2017-2019" cover="tile-wsc" path="/project-wsc"/>
          <Tile line1="Debate Society of Wellspring" line2="Logo Design" line3="2018" cover="tile-dsow" path="/project-dsow"/>
          <Tile line1="Belles Perles" line2="Brand Identity Guidelines" line3="2017" cover="tile-bp" path="/project-bp"/>
        </div>
      </div>
    </div>
  )
}

function DevelopmentGroup() {
  return (
    <div className="group">
      <div className="group-name">
      [ SOFTWARE DEVELOPMENT ]
      </div>
      <div className="column">
        <div className="row">
          <Tile line1="Language Transanslation" line2="Mobile App 'MyTransApp'" line3="2023" cover="tile-transapp" path="https://devpost.com/software/language-translator-app" isExternal/>
          <Tile line1="Task Management" line2="iOS Mobile App 'whatsnext'" line3="2022" cover="tile-whatsnext" path="https://github.com/nglinhchi/whatsnext" isExternal/>
          <Tile line1="Music Mobile App" line2="'Musiversal'" line3="2022" cover="tile-musiversal" path="https://www.figma.com/file/trZICsKJAVzr6NykvTOGQY/Stage-E?type=design&node-id=0%3A1&mode=design&t=iJWysGKxQuBiJzlm-1" isExternal/>
        </div>
        <div className="row">
          <Tile line1="Rogue-like" line2="'Mario Game'" line3="2022" cover="tile-mario" path="https://github.com/nglinhchi/mario-game" isExternal/>
          <Tile line1="Rogue-like" line2="'Pokemon Game'" line3="2022" cover="tile-pokemon" path="https://github.com/nglinhchi/pokemon-game" isExternal/>
          <Tile line1="Timetable Allocation" line2="Web App 'LocateAll+'" line3="2021" cover="tile-locateall" path="https://devpost.com/software/locateall" isExternal/>
        </div>
      </div>
    </div>
  )
}

function Gallery() {
  return (
    <div className="gallery-section">
      <DevelopmentGroup/>
      <DesignGroup/>
    </div>
  );
}

function Home() {

  const web_name = "CHLOE LINH CHI NGUYEN";

  return (
    <div className="home">
      <Introduction web_name={web_name}/>
      <Gallery/>
    </div>
    
  );
}

export default Home;
