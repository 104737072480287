import React from 'react';
import "./DesignProject.css";

function ProjectWsc() {
  return (
    
    <div className='design-project'>
      
      <div className='header'>
        <h1>project</h1>
        <h2>Wellspring <br/> Student Council 'Compiled Work'</h2>
        <p>During my two-year involvement with Wellspring Student Council, 
          the largest student-led club at Wellspring High School, I have had the 
          honor of contributing to a wide range of projects. In addition to my 
          day-to-day responsibilities, which involve maintaining and promoting a 
          consistent professional image for the club, I've been actively involved 
          in various initiatives. These contributions have not only left a lasting 
          impact but also served as guiding principles for future generations of 
          the club.</p>
        <div className='set'>
          <div className='skill'>Multimedia Publications</div>
          <div className='skill'>Logo Design</div>
        </div>
        <div className='set'>
          <div className='stack'>Illustrator</div>
          <div className='stack'>Photoshop</div>
          <div className='stack'>Indesign</div>
        </div>
      </div>

      <div className='medium-space'></div>
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/1.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/2.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/3.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/4.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/5.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/6.png"} alt="wsc stuff" />
      <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/wsc/7.png"} alt="wsc stuff" />
      
    </div>

  );
}

export default ProjectWsc;