import React from 'react';
import "./DesignProject.css";

function ProjectAsnz() {
    return (
      
      <div className='design-project'>
      
        <div className='header'>
          <h1>project</h1>
          <h2>Aviation School<br/>of New Zealand<br/>Brand Identity Guidelines</h2>
          <p>I was commissioned by Aviation School of New Zealand to expand their brand presence 
            as they ventured into Vietnam with a new campus. Building upon their existing logo, I created 
            a sub-logo version incorporating the institution's name in Vietnamese. I also developed comprehensive 
            brand identity guidelines, ensuring consistent branding across office stationery, uniforms, and various 
            marketing materials.
          </p>
          <div className='set'>
            <div className='skill'>Logo Design</div>
            <div className='skill'>Brand Identity Guidelines</div>
          </div>
          <div className='set'>
            <div className='stack'>Photoshop</div>
            <div className='stack'>Illustrator</div>
            <div className='stack'>Indesign</div>
          </div>
        </div>
        <div className='medium-space'></div>

        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/0.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/1.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/2.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/3.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/4.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/5.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/6.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/7.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/8.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/9.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/10.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/11.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/12.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/13.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/14.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/15.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/16.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/17.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/18.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/19.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/20.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/21.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/22.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/23.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/24.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/25.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/26.png"} alt="asnz stuff" />
        <img className='showcase-80h' src={process.env.PUBLIC_URL + "/projects/asnz/27.png"} alt="asnz stuff" />
        <div className='medium-space'></div>
        
      </div>

    );
  }

  export default ProjectAsnz;