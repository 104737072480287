import React from 'react';
import "./DesignProject.css";
// import ImageGallery from './ImageGallery';

function ProjectBp() {
  return (
    
    <div className='design-project'>
      
      <div className='header'>
        <h1>project</h1>
        <h2>Belles Perles<br/>Brand Identity Guidelines</h2>
        <p>As the culmination of my Certificate in Multimedia course, our team of three, 
          including myself, embarked on a creative journey to craft a brand identity that 
          exudes luxury and sophistication. We proudly introduce 'Belles Perles,' an exquisite 
          high-end jewelry brand that epitomises timeless elegance.
        </p>
        <div className='set'>
          <div className='skill'>Logo Design</div>
          <div className='skill'>Brand Identity Guidelines</div>
        </div>
        <div className='set'>
          <div className='stack'>Photoshop</div>
          <div className='stack'>Illustrator</div>
          <div className='stack'>Indesign</div>
        </div>
      </div>
      <div className='medium-space'></div>
      <img className='showcase-single' src={process.env.PUBLIC_URL + "/projects/bp/1.png"} alt="bp stuff"/>
      <ImageGallery startIndex={4} endIndex={111} projectName="bp" />
      <div className='medium-space'></div>
      
    </div>

  );
}

function ImageGallery({ startIndex, endIndex, projectName }) {
  const images = [];

  // Create an array of image pairs
  for (let i = startIndex; i <= endIndex; i += 2) {
    images.push([i, i + 1]);
  }

  return (
    <div className="image-gallery">
      {images.map((imagePair, index) => (
        <div key={index} className="showcase-double">
          <img
            className="showcase-80h"
            src={`${process.env.PUBLIC_URL}/projects/${projectName}/${imagePair[0]}.png`}
            alt={`stuff ${imagePair[0]}`}
          />
          <img
            className="showcase-80h"
            src={`${process.env.PUBLIC_URL}/projects/${projectName}/${imagePair[1]}.png`}
            alt={`stuff ${imagePair[1]}`}
          />
        </div>
      ))}
    </div>
  );
}

export default ProjectBp;